/*
* plugins.js
* Plik z pluginami wczytywany na kazdej stronie serwisu
 */

//=require vendor/select2-4.0.5/select2.full.js
//=require vendor/select2-4.0.5/i18n/pl.js
//=require vendor/js.cookie.js
//=require vendor/lazyload/jquery.lazyload.js
//=require vendor/rateyo-2.3.2/jquery.rateyo.js
//=require vendor/owlcarousel-2.2.1/owl.carousel.js
//=require vendor/mask-1.14/jquery.mask.min.js
//=require vendor/autocomplete/jquery.autocomplete.js
//=require vendor/featherlight-1.7.9/featherlight.js
//=require vendor/featherlight-1.7.9/featherlight.gallery.js  
//=require vendor/unitegallery-1.7.45/unitegallery.min.js
//=require vendor/unitegallery-1.7.45/ug-theme-tiles.js
